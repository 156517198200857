import React from 'react';

const CommentCardLoading = () => {
  return (
    <div className="flex space-x-20 pt-12">
      <div className="h-32 w-32 animate-pulse rounded-full bg-npl-neutral-light-solid-6"></div>
      <div className="flex-grow">
        <div className="mb-[2px] h-16 w-[48px] animate-pulse rounded-[6px] bg-npl-neutral-light-solid-6"></div>
        <div className="mb-6 h-16 w-full animate-pulse rounded-[6px] bg-npl-neutral-light-solid-6"></div>
        <div className="h-[40px] w-full animate-pulse rounded-[6px] bg-npl-neutral-light-solid-6"></div>
        <div className="mb-8 mt-16 flex items-center">
          <div className="h-[20px] w-[28px] animate-pulse rounded-[6px] bg-npl-neutral-light-solid-6"></div>
          <div className="mx-12 text-label-sm font-medium text-npl-neutral-light-solid-6">
            |
          </div>
          <div className="h-[20px] w-40 animate-pulse rounded-[6px] bg-npl-neutral-light-solid-6"></div>
        </div>
      </div>
    </div>
  );
};

export default CommentCardLoading;
