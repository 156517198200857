// useGetProfileDetails.tsx
import { useMemo } from 'react';
import useSWR from 'swr';

import config from '@/utility/config';

import { protectedAxiosFetcher } from '@/services/helpers/swr';

import { showErrorToast } from '@/components/common/ToastContainer';

type Props = {
  communityId: string;
  learnerId: string;
  shouldFetch: boolean;
};

const useGetProfileDetails = ({
  communityId,
  learnerId,
  shouldFetch
}: Props) => {
  const { data, error, isLoading } = useSWR(
    shouldFetch
      ? `${config.communityApiBasePath}/api/v1/community-landing-page/${communityId}/members/${learnerId}`
      : null,
    protectedAxiosFetcher
  );

  if (error) {
    showErrorToast(error);
  }

  const selectedMemberDetails = useMemo(() => {
    if (!data) return null;
    const profileData = data?.data;

    const socialMediaObject =
      profileData?.socialMedia?.reduce(
        (acc, media) => ({
          ...acc,
          [media.type]: media.link
        }),
        {}
      ) || {};

    return {
      profileImage: profileData?.profileImage,
      fullName: profileData?.name,
      description: profileData?.bio,
      country: profileData?.country?.name,
      joinDate: profileData?.subscriptionInfo?.signUpDate,
      socialMedia: socialMediaObject
    };
  }, [data]);

  return { isLoading, selectedMemberDetails };
};

export default useGetProfileDetails;
