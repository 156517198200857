import React, { FC, useRef } from 'react';

import { useWindowWidthContext } from '@/contexts/WindowWidthContext';

import type { IconName } from '@/components/npl/Icon';

import useOutsideComponentClickDetect from '@/hooks/useOutsideComponentClickDetect';

import InfiniteList from '../InfiniteList';
import PopOverModal from '../PopOverModal';
import NPLPopOverOption from './NPLPopOverOption';

export type Option = {
  description?: string;
  icon?: IconName;
  id?: string;
  index?: number;
  isDanger?: boolean;
  onClick?: () => void;
  title?: string;
};

type InfiniteListProps = {
  hasMore: boolean;
  isLoading: boolean;
  loadMore: () => void;
};

type NplPopoverMenuProps = {
  onClose: () => void;
  options: Option[];
  infiniteListProps?: InfiniteListProps;
  maxWidth?: string;
  onOptionClick?: (option: Option) => void;
  optionsBelowDivider?: Option[];
  selectedOptionId?: string;
};

const NplPopoverMenu: FC<NplPopoverMenuProps> = ({
  options,
  onClose,
  maxWidth,
  onOptionClick = () => {},
  optionsBelowDivider = [],
  infiniteListProps,
  selectedOptionId
}) => {
  const isDangerClassName = '!text-npl-error-light-11';
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { isGtEqMd } = useWindowWidthContext();

  useOutsideComponentClickDetect(dropdownRef, () => {
    onClose();
  });

  const allOptions = [...options, ...optionsBelowDivider];

  const renderOption = (option: Option) => (
    <NPLPopOverOption
      key={option.id}
      option={option}
      onClose={onClose}
      onOptionClick={onOptionClick}
      isDangerClassName={isDangerClassName}
      isSelected={
        selectedOptionId ? option.id === selectedOptionId : false
      }
    />
  );

  const renderOptions = (optionsToRender: Option[]) => (
    <>{optionsToRender.map((option) => renderOption(option))}</>
  );

  const renderOptionsInInfiniteList = (optionsToRender: Option[]) => (
    <InfiniteList items={optionsToRender} {...infiniteListProps}>
      {(option: Option) => renderOption(option)}
    </InfiniteList>
  );

  if (!isGtEqMd) {
    return (
      <PopOverModal
        open={true}
        onClose={onClose}
        showCloseIcon={true}
        size="xl"
        customContainerClass="bg-white-default p-8">
        {infiniteListProps
          ? renderOptionsInInfiniteList(allOptions)
          : renderOptions(allOptions)}
      </PopOverModal>
    );
  }

  return (
    <div
      ref={dropdownRef}
      className={`c-NplPopoverMenu min-w-[200px] max-h-300 overflow-y-auto rounded-12 bg-white-default p-8 shadow-sm ${maxWidth}`}>
      {infiniteListProps
        ? renderOptionsInInfiniteList(allOptions)
        : renderOptions(allOptions)}
      {optionsBelowDivider?.length > 0 && (
        <>
          <div className="my-8 w-full border-t-1 border-npl-neutral-light-solid-4"></div>
          {renderOptions(optionsBelowDivider)}
        </>
      )}
    </div>
  );
};

export default NplPopoverMenu;
