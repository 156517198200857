import React from 'react';

import { t } from '@/utility/localization';

import Modal from '@/components/common/Modal';
import NPLButton from '@/components/npl/NPLButton';

const CommentDeleteModal = ({
  open = false,
  isLoading = false,
  onClose = () => {},
  onSubmit = () => {}
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      size="md"
      customInnerClass="!py-16 !px-24">
      <div className="font-poppins">
        <p className="mb-8 text-heading-sm font-semibold text-npl-text-icon-on-light-surface-primary">
          {t('delete-comment')}
        </p>
        <p className="mb-24 text-body-sm text-npl-text-icon-on-light-surface-primary opacity-60">
          {t('are-you-sure-you-want-to-delet')}
        </p>
        <div className="flex justify-end space-x-12">
          <NPLButton
            hierarchy="outline"
            onClick={onClose}
            buttonText={t('cancel')}
          />
          <NPLButton
            hierarchy="destructive"
            onClick={onSubmit}
            isLoading={isLoading}
            buttonText={t('delete')}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CommentDeleteModal;
