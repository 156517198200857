import React from 'react';

import { getTimeAgoString } from '@/utility/dateHelper';

import ProfilePopUpWrapper from '@/features/ProfilePopUp/ProfilePopUpWrapper';

import NextImage from '@/components/common/NextImage';
import Icon from '@/components/npl/Icon';

import { getPostAccessIcon } from '../../utils';
import { PostCreatorTag } from './CreatorPostTag';

const className = 'c-PostAuthorData';

const getProfileImageFormatted = (url) => {
  return {
    alt: 'profile image',
    mobileImgProps: {
      src: url,
      width: 32,
      height: 32,
      quality: 50,
      objectFit: 'cover'
    }
  };
};

const PostAuthorData = ({
  communityId = '',
  post = null,
  showPostVisibilityType = true,
  onSelectedMemberDetails = null
}) => {
  if (!post) return null;
  const postAccessIcon = getPostAccessIcon(post);
  const { author, postedBy } = post;
  const isCreatorPost = postedBy === 'CREATOR';
  return (
    <div className={`${className} flex items-center space-x-12`}>
      {/* Author Image */}
      {/* TODO:@Trent Refactor this entirely to use wrapper */}
      {!onSelectedMemberDetails ? (
        <div
          className="h-32 w-32 overflow-hidden rounded-full"
          role="button"
          tabIndex={0}>
          <ProfilePopUpWrapper
            communityId={communityId}
            learnerId={author?.learnerObjectId}>
            <NextImage
              {...getProfileImageFormatted(author?.profileImage)}
              className="h-32 w-32 rounded-full"
            />
          </ProfilePopUpWrapper>
        </div>
      ) : (
        <div
          className="h-32 w-32 overflow-hidden rounded-full"
          role="button"
          tabIndex={0}
          onClick={(ev) => {
            ev?.preventDefault();
            ev?.stopPropagation();
            onSelectedMemberDetails?.(author);
          }}>
          <NextImage
            {...getProfileImageFormatted(author?.profileImage)}
            className="h-32 w-32 rounded-full"
          />
        </div>
      )}

      <div className="">
        {/* Author Name */}
        <div className="flex flex-row gap-4 items-center ">
          <p className="text-label-md text-npl-text-primary-on-light font-medium">
            {author?.firstName ?? ''} {author?.lastName ?? ''}
          </p>
          {isCreatorPost ? <PostCreatorTag /> : null}
        </div>

        {/* Post visibility & Time Ago String */}
        <div className="flex items-center space-x-4 text-[#1B1B1880]">
          {/* Created At */}
          <p className="text-label-sm text-dark-1b text-opacity-65">
            {getTimeAgoString(post?.createdAt)}
          </p>
          {showPostVisibilityType ? (
            <>
              <div>·</div>
              <Icon name={postAccessIcon} fill="#1B1B1880" width={16} />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PostAuthorData;
