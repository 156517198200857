import { useCallback, useEffect, useRef, useState } from 'react';

import { listReactions } from '@/services/community/reactionsService';

const DEFAULT_PAGE_NUMBER = 1;
const DEFAULTPAGE_SIZE = 10;

const useReactionList = ({ type, communityId, postId, commentId }) => {
  const loaderRef = useRef(null);

  const [userList, setUserList] = useState([]);
  const [listMetadata, setListMetadata] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);

  const fetchMoreReaction = useCallback(() => {
    if (listMetadata?.next) setPageNumber((prev) => prev + 1);
  }, [listMetadata?.next]);

  const fetchUserReactionList = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data, error } = await listReactions({
        communityId,
        postId,
        params: {
          pageNum: pageNumber,
          pageSize: DEFAULTPAGE_SIZE,
          paginate: 1,
          type: 'like'
        },
        postType: type,
        commentId
      });

      if (error) throw new Error(error);

      const { data: reactionData } = data;

      setUserList((prev) => {
        return [...prev, ...(reactionData?.reactions?.data || [])];
      });
      setListMetadata(reactionData?.reactions?.metadata);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [commentId, communityId, pageNumber, postId, type]);

  useEffect(() => {
    fetchUserReactionList();
  }, [fetchUserReactionList]);

  useEffect(() => {
    let currentObserver = new IntersectionObserver(
      (entry) => {
        if (entry[0]?.isIntersecting) fetchMoreReaction();
      },
      { threshold: 1 }
    );

    if (currentObserver && loaderRef.current)
      currentObserver.observe(loaderRef.current);

    return () => {
      currentObserver.disconnect();
    };
  }, [fetchMoreReaction]);

  return {
    loaderRef,
    userList,
    isLoading
  };
};

export default useReactionList;
