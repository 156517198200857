import config from '@/utility/config';
import { getQueryStringFromObject } from '@/utility/routesHelper';

import { getAxiosError } from '../helpers';
import adminProtectedAxiosRequest from '../helpers/adminProtectedAxiosRequest';
import authorizedRequest from '../helpers/authorizedRequest';

const { communityApiBasePath } = config;

export const getPostComments = async ({
  communityId,
  postId,
  params = {}
}) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/announcements/${postId}/comment?${getQueryStringFromObject(
      {
        queryParams: { ...params, type: 'comment' }
      }
    )}`;

    const { data, error } = await authorizedRequest(path, {
      method: 'GET'
    });

    if (error) {
      return { error };
    }

    return { data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

export const getCommentReplies = async ({
  communityId,
  postId,
  commentId,
  params
}) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/announcements/${postId}/comment/${commentId}/reply?${getQueryStringFromObject(
      {
        queryParams: params
      }
    )}`;

    const { data, error } = await authorizedRequest(path, {
      method: 'GET'
    });

    if (error) {
      return { error };
    }

    return { data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

export const addPostComments = async ({ communityId, postId, params }) => {
  try {
    return await adminProtectedAxiosRequest.post(
      `${communityApiBasePath}/api/v1/communities/${communityId}/announcements/${postId}/comment`,
      params
    );
  } catch (error) {
    return getAxiosError(error);
  }
};

export const deletePostComments = async ({
  communityId,
  postId,
  commentId,
  params
}) => {
  try {
    return await adminProtectedAxiosRequest.delete(
      `${communityApiBasePath}/api/v1/communities/${communityId}/announcements/${postId}/comment/${commentId}`,
      { params }
    );
  } catch (error) {
    return getAxiosError(error);
  }
};
