import config from '@/utility/config';
import { getQueryStringFromObject } from '@/utility/routesHelper';

import { getAxiosError } from '../helpers';
import adminProtectedAxiosRequest from '../helpers/adminProtectedAxiosRequest';
import authorizedRequest from '../helpers/authorizedRequest';

const { communityApiBasePath } = config;

export const listReactions = async ({
  communityId,
  postId,
  params,
  postType,
  commentId
}) => {
  try {
    const pathWoQuery =
      postType === 'post'
        ? `${communityApiBasePath}/api/v1/communities/${communityId}/announcements/${postId}/reaction`
        : `${communityApiBasePath}/api/v1/communities/${communityId}/announcements/${postId}/comment/${commentId}/reaction`;

    const pathWithQuery = `${pathWoQuery}?${getQueryStringFromObject({
      queryParams: params
    })}`;

    const { data, error } = await authorizedRequest(pathWithQuery, {
      method: 'GET'
    });

    if (error) {
      return { error };
    }

    return { data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

export const postReaction = async ({
  postType,
  communityId,
  postId,
  commentId,
  reactionType = 'like'
}) => {
  try {
    const path =
      postType === 'post'
        ? `${communityApiBasePath}/api/v1/communities/${communityId}/announcements/${postId}/reaction`
        : `${communityApiBasePath}/api/v1/communities/${communityId}/announcements/${postId}/comment/${commentId}/reaction`;
    const payload = {
      type: reactionType
    };
    return await adminProtectedAxiosRequest.post(path, payload);
  } catch (error) {
    return getAxiosError(error);
  }
};

export const deleteReaction = async ({
  postType,
  communityId,
  postId,
  commentId,
  reactionType = 'like'
}) => {
  try {
    const path =
      postType === 'post'
        ? `${communityApiBasePath}/api/v1/communities/${communityId}/announcements/${postId}/reaction`
        : `${communityApiBasePath}/api/v1/communities/${communityId}/announcements/${postId}/comment/${commentId}/reaction`;
    const payload = {
      type: reactionType
    };
    return await adminProtectedAxiosRequest.delete(path, payload);
  } catch (error) {
    return getAxiosError(error);
  }
};
