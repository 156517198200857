/**
 * Prefix a URL with https if it doesn't already have a protocol
 * @param {string} url
 */
export const prefixWithHttps = (url) => {
  if (url && !url.startsWith('http')) {
    return `https://${url}`;
  }

  return url;
};

export const roundFormatNumber = (number) => {
  if (number >= 1000000) {
    return `${Math.round(number / 1000000)}M`;
  }

  if (number >= 1000) {
    return `${Math.round(number / 1000)}K`;
  }

  return number;
};
