import dynamic from 'next/dynamic';
import React, { FC, useState } from 'react';

import NPLModal from '@/components/npl/NPLModal/NPLModal';

import useGetProfileDetails from './hooks/useGetProfileDetails';

const PreviewProfile = dynamic(
  () => import('../User/CompleteYourProfileModal/steps/PreviewProfile'),
  {
    ssr: false
  }
);

type Props = {
  children: React.ReactNode;
  communityId: string;
  learnerId: string;
};

const ProfilePopUpWrapper: FC<Props> = ({
  communityId,
  learnerId,
  children
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { isLoading, selectedMemberDetails } = useGetProfileDetails({
    communityId,
    learnerId,
    shouldFetch: isOpen
  });

  const handleClick = (e) => {
    e?.stopPropagation();
    setIsOpen(true);
  };
  const handleClose = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    setIsOpen(false);
  };

  return (
    <>
      <div
        className="hover:cursor-pointer"
        role="button"
        tabIndex={0}
        onClick={handleClick}>
        {children}
      </div>
      {isOpen && selectedMemberDetails && (
        <NPLModal
          size="md-550"
          open={isOpen}
          showCloseIcon={true}
          onClose={handleClose}>
          <PreviewProfile
            userData={selectedMemberDetails}
            justViewingProfile={true}
            isLoading={isLoading}
          />
        </NPLModal>
      )}
    </>
  );
};

export default ProfilePopUpWrapper;
