import React, { FC } from 'react';

import withComponentClassName from '@/utility/withComponentClassName';

import Icon from '@/components/npl/Icon';
import type { IconName } from '@/components/npl/Icon';

interface IconWithTextSectionProps {
  icon: IconName<'common'>;
  iconColor: string;
  iconWidthHeight: number;
  title: string | number;
}

const IconWithTextSection: FC<IconWithTextSectionProps> = ({
  icon,
  iconColor = '#1B1B18A6',
  iconWidthHeight = 16,
  title
}) => {
  return (
    <div className="flex items-center justify-center space-x-4">
      <Icon
        name={icon}
        width={iconWidthHeight}
        height={iconWidthHeight}
        fill={iconColor}
        fillOpacity={1}
      />
      {title && (
        <p className="text-label-md font-medium text-npl-text-icon-on-light-surface-secondary">
          {title}
        </p>
      )}
    </div>
  );
};

export default withComponentClassName(IconWithTextSection);
