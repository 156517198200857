import React, { useRef } from 'react';

type InfiniteListProps = {
  children: (item: unknown, index: number) => React.ReactNode;
  hasMore: boolean;
  items: unknown[];
  loadMore: () => void;
  isChunked?: boolean;
  isLoading?: boolean;
  renderSkeleton?: () => React.ReactNode;
};

const InfiniteList: React.FC<InfiniteListProps> = ({
  items,
  hasMore,
  loadMore,
  children,
  isLoading = false,
  isChunked = false,
  renderSkeleton = null
}) => {
  const observer = useRef<IntersectionObserver>();
  const lastElementRef = (node: HTMLElement | null) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadMore();
      }
    });

    if (node) observer.current.observe(node);
  };

  const renderedItems = isChunked ? items.flat() : items || [];

  return (
    <>
      {renderedItems.map((item, index) => (
        <React.Fragment key={index}>
          {children(item, index)}
        </React.Fragment>
      ))}
      {isLoading ? (
        renderSkeleton?.()
      ) : (
        <>{hasMore && <div ref={lastElementRef} />}</>
      )}
    </>
  );
};

export default InfiniteList;
