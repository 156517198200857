import React, { useState } from 'react';

import PostActionButton from '@/features/Feed/pages/PostPublicPage/PostActionButton';
import ProfilePopUpWrapper from '@/features/ProfilePopUp/ProfilePopUpWrapper';

import Image from '@/components/common/Image';
import LinkAwareText from '@/components/common/LinkAwareText';

import usePostReaction from '@/hooks/usePostReaction';

import CommentHeaderSection from '../CommentCard/CommentHeaderSection';
import CommentDeleteModal from '../CommentDeleteModal';
import { ReactionModal } from '../Reaction/ReactionModal';

const ReplyCard = ({
  reply,
  communityId,
  postId,
  commentId,
  deleteLoadingMap,
  openReplyInput,
  onReplyDelete,
  updateAnnouncementInfo,
  post,
  allowReaction,
  allowReply,
  allowDelete
}) => {
  const {
    _id: replyId,
    profileImage,
    firstName,
    lastName,
    createdAt,
    content
  } = reply;

  const {
    isPostLiked: isReplyLiked,
    postLikeCount: replyLikeCount,
    handleReactionButtonClick
  } = usePostReaction({
    post: reply,
    postType: 'comment',
    communityId,
    postId,
    commentId: replyId
  });

  const [isReactionModalVisible, setIsReactionModalVisible] =
    useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  return (
    <div className="flex space-x-8">
      <div className="mt-8 h-32 w-32 flex-shrink-0 overflow-hidden rounded-full">
        <ProfilePopUpWrapper
          communityId={communityId}
          learnerId={reply?.learnerObjectId}>
          <Image
            alt="user-avatar"
            mobileImgData={{
              src: profileImage,
              width: 32,
              height: 32
            }}
            customImgClassname="rounded-full h-32 w-32"
          />
        </ProfilePopUpWrapper>
      </div>
      <div className="flex-grow">
        <div className="relative rounded-12 bg-npl-neutral-light-solid-2">
          <CommentHeaderSection
            firstName={firstName}
            lastName={lastName}
            createdAt={createdAt}
            allowDelete={allowDelete}
            onDeleteClick={() => setIsDeleteModalOpen(true)}
          />
          <div className="break-word whitespace-pre-line text-body-sm text-npl-text-icon-on-light-surface-primary px-12 pb-8">
            <LinkAwareText text={content} />
          </div>
        </div>
        <div className="flex items-center gap-4">
          <div className="flex items-center">
            {allowReaction && (
              <>
                {isReplyLiked && (
                  <PostActionButton
                    icon="heart-filled"
                    iconColor={'#E5484D'}
                    onClick={handleReactionButtonClick}
                    size={16}
                    title={replyLikeCount > 0 ? replyLikeCount : ''}
                  />
                )}
                {!isReplyLiked && (
                  <PostActionButton
                    icon={'heart'}
                    size={16}
                    iconColor={'#1B1B18A6'}
                    onClick={handleReactionButtonClick}
                    title={replyLikeCount > 0 ? replyLikeCount : ''}
                  />
                )}
              </>
            )}
          </div>
          <div className="flex items-center">
            {allowReply && (
              <PostActionButton
                icon="message-text-square-02"
                size={16}
                iconColor="#1B1B18A6"
                onClick={() => openReplyInput({ parentComment: reply })}
              />
            )}
          </div>
        </div>
      </div>

      {isReactionModalVisible && (
        <ReactionModal
          type="comment"
          isLiked={isReplyLiked}
          communityId={communityId}
          postId={postId}
          commentId={replyId}
          onClose={() => setIsReactionModalVisible(false)}></ReactionModal>
      )}

      <CommentDeleteModal
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        isLoading={deleteLoadingMap?.[replyId]}
        onSubmit={() =>
          onReplyDelete({
            postId,
            commentId,
            replyId,
            onSuccess: () => {
              updateAnnouncementInfo({
                post,
                updatedInfo: {
                  commentCount: (post?.commentCount ?? 0) - 1
                }
              });
              setIsDeleteModalOpen(false);
            }
          })
        }
      />
    </div>
  );
};

export default ReplyCard;
