// NPLModal.tsx
import style from './NPLModal.module.scss';

import classNames from 'classnames';
import { FC, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';

import { useWindowWidthContext } from '@/contexts/WindowWidthContext';

import NPLIconButton from '../NPLIconButton';

const sizesMap = {
  sm: 'max-w-300',
  'md-400': 'max-w-[400px]',
  'md-420': 'max-w-[420px]',
  md: 'max-w-500',
  'md-550': 'max-w-[550px]',
  'md-560': 'max-w-[560px]',
  'md-640': 'max-w-[640px]',
  lg: 'max-w-800',
  xl: 'max-w-1140',
  default: 'max-w-auto'
};

interface NPLModalProps {
  children: React.ReactNode;
  onClose: (e: React.MouseEvent) => void;
  closeOnBackdropClick?: boolean;
  eyebrow?: React.ReactNode;
  leftIcon?: React.ReactNode;
  open?: boolean;
  outsideComponent?: React.ReactNode;
  showCloseIcon?: boolean;
  size?: keyof typeof sizesMap;
}

const NPLModal: FC<NPLModalProps> = ({
  open = false,
  onClose,
  showCloseIcon = true,
  closeOnBackdropClick = true,
  children,
  size = 'default',
  outsideComponent = null
}) => {
  const { isGtEqMd } = useWindowWidthContext();
  const className = isGtEqMd ? 'c-NPLDesktopModal' : 'c-NPLMobileModal';

  const baseClassNames = classNames(className, style[className], {
    [style[`${className}--open`]]: open
  });

  const containerClassNames = classNames(
    `${className}__container`,
    'animate-fadeInModal',
    style[`${className}__container`],
    sizesMap[size],
    'flex flex-col items-stretch'
  );

  const handleBackdropClick = (e) => {
    if (closeOnBackdropClick) onClose?.(e);
  };

  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;

    if (open) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow =
        originalStyle === 'hidden' ? 'initial' : originalStyle;
    };
  }, [open]);

  if (typeof window === 'undefined') return null;

  return createPortal(
    <div
      className={`${baseClassNames} z-over-intercom absolute`}
      data-testid="modal">
      <div className="fixed bg-npl-transparent-black-25 inset-0 w-full h-full backdrop-blur-lg" />
      <div onClick={handleBackdropClick}>
        <div className="fixed inset-0 w-full h-full">
          {outsideComponent}
        </div>
      </div>

      <div
        className={`${containerClassNames} max-h-[calc(100vh-80px)] relative overflow-hidden`}>
        {showCloseIcon && (
          <div className="w-40 h-40 absolute top-8 right-16 ">
            {showCloseIcon && (
              <NPLIconButton
                hierarchy="plain"
                icon="x-close"
                size="md"
                onClick={onClose}
              />
            )}
          </div>
        )}
        <div className="overflow-y-auto">{children}</div>
      </div>
    </div>,
    document.body
  );
};

export default NPLModal;
