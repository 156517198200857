import { useEffect, useState } from 'react';

import {
  addPostComments,
  deletePostComments,
  getCommentReplies,
  getPostComments
} from '@/services/community/postCommentService';

import { showToast } from '@/components/common/ToastContainer';

export const DEFAULT_COMMENT_PAGE_SIZE = 20;

const removeDuplicateAndMerge = ({ list1 = [], list2 = [] }) => {
  try {
    const mergedMap = new Map();

    list1.forEach((item) => mergedMap.set(item._id, item));
    list2.forEach((item) => mergedMap.set(item._id, item));

    const mergedValues = mergedMap.values();

    return Array.from(mergedValues);
  } catch (e) {
    console.error(e);
    return [];
  }
};

const usePostComment = ({ user, activeCommunity, postId }) => {
  const [textFieldValue, setTextFieldValue] = useState('');

  const [commentData, setCommentData] = useState({});
  const [replyData, setReplyData] = useState({});

  const [loadingMap, setLoadingMap] = useState({});
  const [submitLoadingMap, setSubmitLoadingMap] = useState({});
  const [deleteLoadingMap, setDeleteLoadingMap] = useState({});

  const [replyingToCommentData, setReplyingToCommentData] = useState({
    parentCommentObjectId: '',
    _id: '',
    replyCount: 0,
    type: '',
    rootCommentObjectId: ''
  });

  const activeCommunityId = activeCommunity?._id;

  const hasUnsavedInputField = textFieldValue !== '';

  const fetchComments = async ({ postId }) => {
    if (!postId) return;
    try {
      /* check if theres already data present
      1. if data is present then toggle collapse and expand
      2. if data is not present then fetch data
      */

      const doesCommentsExist =
        commentData?.[postId]?.commentList?.length > 0;

      // toggle state
      if (doesCommentsExist) {
        const selectedComment = commentData?.[postId];
        const newExpandedState = !selectedComment?.areCommentsExpanded;

        setCommentData((prev) => {
          return {
            ...prev,
            [postId]: {
              ...selectedComment,
              areCommentsExpanded: newExpandedState
            }
          };
        });

        return;
      }

      setLoadingMap((prev) => {
        return { ...prev, [postId]: true };
      });

      const { data, error } = await getPostComments({
        communityId: activeCommunityId,
        postId,
        params: {
          paginate: 1,
          pageNum: 1,
          pageSize: DEFAULT_COMMENT_PAGE_SIZE
        }
      });

      if (error) throw new Error(error);

      const fetchedCommentData = {
        commentList: data?.data?.comments?.data,
        pageNum: 1,
        pageSize: DEFAULT_COMMENT_PAGE_SIZE,
        areCommentsExpanded: true,
        hasNextPage: !!data?.data?.comments?.metadata?.next
      };

      setCommentData((prev) => {
        return { ...prev, [postId]: fetchedCommentData };
      });
    } catch (error) {
      showToast({ type: 'error', text: error.message });
    } finally {
      setLoadingMap((prev) => {
        return { ...prev, [postId]: false };
      });
    }
  };

  const fetchMoreComments = async ({ postId }) => {
    try {
      setLoadingMap((prev) => {
        return { ...prev, [postId]: true };
      });

      const selectedComment = commentData?.[postId];

      if (!selectedComment?.hasNextPage) return;

      const { data, error } = await getPostComments({
        communityId: activeCommunityId,
        postId,
        params: {
          paginate: 1,
          pageNum: selectedComment?.pageNum + 1,
          pageSize: DEFAULT_COMMENT_PAGE_SIZE
        }
      });

      if (error) throw new Error(error);

      const fetchedCommentData = {
        ...selectedComment,
        commentList: removeDuplicateAndMerge({
          list1: selectedComment?.commentList,
          list2: data?.data?.comments?.data
        }),
        pageNum: selectedComment?.pageNum + 1,
        pageSize: DEFAULT_COMMENT_PAGE_SIZE,
        hasNextPage: !!data?.data?.comments?.metadata?.next
      };

      setCommentData((prev) => {
        return { ...prev, [postId]: fetchedCommentData };
      });
    } catch (error) {
      showToast({ type: 'error', text: error.message });
    } finally {
      setLoadingMap((prev) => {
        return { ...prev, [postId]: false };
      });
    }
  };

  const onCommentSubmit = async ({
    postId,
    shouldFetchComment = false,
    onSuccess = () => {}
  }) => {
    // if first comment and has comment call comment fetch
    // loading state
    // make the api call
    // delete the temp text field value on success
    try {
      setSubmitLoadingMap((prev) => {
        return { ...prev, [postId]: true };
      });

      const { data, error } = await addPostComments({
        postId,
        communityId: activeCommunityId,
        params: {
          type: 'comment',
          category: 'text',
          content: textFieldValue
        }
      });

      const newComment = {
        ...(data?.data ?? {}),
        profileImage: user?.learner?.profileImage,
        firstName: user?.learner?.firstName,
        lastName: user?.learner?.lastName,
        learnerObjectId: user?.learner?._id,
        type: 'comment'
      };

      if (error) throw new Error(error);

      //empty text field map
      setTextFieldValue('');

      const tempCommentData = commentData?.[postId] ?? {};
      const tempCommentList = tempCommentData?.commentList ?? [];
      tempCommentList?.unshift(newComment);

      setCommentData((prev) => {
        return {
          ...prev,
          [postId]: {
            ...tempCommentData,
            commentList: tempCommentList,
            areCommentsExpanded: true
          }
        };
      });

      if (shouldFetchComment) fetchComments({ postId });

      onSuccess();
    } catch (error) {
      showToast({ type: 'error', text: error.message });
    } finally {
      setSubmitLoadingMap((prev) => {
        return { ...prev, [postId]: false };
      });
    }
  };

  const onCommentDelete = async ({
    postId,
    commentId,
    onSuccess = () => {}
  }) => {
    /**
     * set delete loading state
     * make api call for delete
     * delete comment from state
     * delete entry in reply data
     */
    try {
      if (deleteLoadingMap?.[commentId]) return;

      setDeleteLoadingMap((prev) => {
        return { ...prev, [commentId]: true };
      });

      const { error } = await deletePostComments({
        postId,
        communityId: activeCommunityId,
        commentId,
        params: {
          type: 'comment'
        }
      });

      if (error) throw new Error(error);

      // success

      const tempCommentData = commentData?.[postId];
      const tempCommentList = tempCommentData?.commentList?.filter(
        (comment) => comment?._id !== commentId
      );
      tempCommentData.commentList = tempCommentList;

      setCommentData((prev) => {
        return { ...prev, [postId]: tempCommentData };
      });

      //Delete reply map corresponding to that comment
      const tempReplyData = { ...replyData };
      delete tempReplyData?.[commentId];

      setReplyData(tempReplyData);

      onSuccess();
    } catch (error) {
      showToast({ text: error.message, type: 'error' });
    } finally {
      setDeleteLoadingMap((prev) => {
        return { ...prev, [commentId]: false };
      });
    }
  };

  const fetchReplies = async ({ commentId, postId }) => {
    try {
      /* check if theres already data present
      1. if data is present then toggle collapse and expand
      2. if data is not present then fetch data
      */

      const doesRepliesExist =
        replyData?.[commentId]?.replyList?.length > 0;

      // toggle state
      if (doesRepliesExist) {
        const selectedReply = replyData?.[commentId];
        const newExpandedState = !selectedReply?.areRepliesExpanded;

        setReplyData((prev) => {
          return {
            ...prev,
            [commentId]: {
              ...selectedReply,
              areRepliesExpanded: newExpandedState
            }
          };
        });

        return;
      }

      setLoadingMap((prev) => {
        return { ...prev, [commentId]: true };
      });

      const { data, error } = await getCommentReplies({
        communityId: activeCommunityId,
        commentId,
        postId,
        params: {
          paginate: 1,
          pageNum: 1,
          pageSize: DEFAULT_COMMENT_PAGE_SIZE
        }
      });

      if (error) throw new Error(error);

      const fetchedReplyData = {
        replyList: data?.data?.comments?.data,
        pageNum: 1,
        pageSize: DEFAULT_COMMENT_PAGE_SIZE,
        areRepliesExpanded: true,
        hasNextPage: !!data?.data?.comments?.metadata?.next
      };

      setReplyData((prev) => {
        return { ...prev, [commentId]: fetchedReplyData };
      });
    } catch (error) {
      showToast({ type: 'error', text: error.message });
    } finally {
      setLoadingMap((prev) => {
        return { ...prev, [commentId]: false };
      });
    }
  };

  const fetchMoreReplies = async ({ postId, commentId }) => {
    try {
      setLoadingMap((prev) => {
        return { ...prev, [commentId]: true };
      });

      const selectedReply = replyData?.[commentId];

      if (!selectedReply?.hasNextPage) return;

      const { data, error } = await getCommentReplies({
        communityId: activeCommunityId,
        commentId,
        postId,
        params: {
          paginate: 1,
          pageNum: selectedReply?.pageNum + 1,
          pageSize: DEFAULT_COMMENT_PAGE_SIZE
        }
      });

      if (error) throw new Error(error);

      const fetchedReplyData = {
        ...selectedReply,
        replyList: [
          ...(selectedReply?.replyList || []),
          ...(data?.data?.comments?.data || [])
        ],
        pageNum: selectedReply?.pageNum + 1,
        pageSize: DEFAULT_COMMENT_PAGE_SIZE,
        hasNextPage: !!data?.data?.comments?.metadata?.next
      };

      setReplyData((prev) => {
        return { ...prev, [commentId]: fetchedReplyData };
      });
    } catch (error) {
      showToast({ type: 'error', text: error.message });
    } finally {
      setLoadingMap((prev) => {
        return { ...prev, [commentId]: false };
      });
    }
  };

  const onReplySubmit = async ({
    commentId,
    postId,
    parentCommentId,
    shouldFetchReply = false,
    onSuccess = () => {}
  }) => {
    // if first reply and has reply, call reply fetch
    // loading state
    // make the api call
    // delete the temp text field value on success
    try {
      setSubmitLoadingMap((prev) => {
        return { ...prev, [commentId]: true };
      });

      const { data, error } = await addPostComments({
        postId,
        communityId: activeCommunityId,
        params: {
          type: 'reply',
          category: 'text',
          content: textFieldValue,
          rootCommentObjectId: commentId,
          parentCommentObjectId: parentCommentId
        }
      });

      const newComment = {
        ...(data?.data ?? {}),
        profileImage: user?.learner?.profileImage,
        firstName: user?.learner?.firstName,
        lastName: user?.learner?.lastName,
        learnerObjectId: user?.learner?._id,
        type: 'reply'
      };

      if (error) throw new Error(error);

      // success
      //empty text field map
      setTextFieldValue('');

      const tempReplyData = replyData?.[commentId] ?? {};
      const tempReplyList = tempReplyData?.replyList ?? [];
      tempReplyList?.push(newComment);

      setReplyData((prev) => {
        return {
          ...prev,
          [commentId]: {
            ...tempReplyData,
            replyList: tempReplyList,
            areRepliesExpanded: true
          }
        };
      });

      if (shouldFetchReply) fetchReplies({ postId, commentId });

      //update reply count
      const tempCommentData = commentData?.[postId];
      const tempCommentList = tempCommentData?.commentList;
      const findCommentIndex = tempCommentList?.findIndex(
        (comment) => comment?._id === commentId
      );
      const selectedComment = tempCommentList?.[findCommentIndex];
      selectedComment.replyCount = (selectedComment?.replyCount ?? 0) + 1;
      tempCommentList[findCommentIndex] = selectedComment;
      tempCommentData.commentList = tempCommentList;

      setCommentData((prev) => {
        return { ...prev, [postId]: tempCommentData };
      });

      onSuccess();
    } catch (error) {
      showToast({ type: 'error', text: error.message });
    } finally {
      setSubmitLoadingMap((prev) => {
        return { ...prev, [commentId]: false };
      });
    }
  };

  const onReplyDelete = async ({
    postId,
    commentId,
    replyId,
    onSuccess = () => {}
  }) => {
    /**
     * set delete loading state
     * make api call for delete
     * delete comment from state
     * delete entry in reply data
     */
    try {
      if (deleteLoadingMap?.[replyId]) return;

      setDeleteLoadingMap((prev) => {
        return { ...prev, [replyId]: true };
      });

      const { error } = await deletePostComments({
        postId,
        communityId: activeCommunityId,
        commentId: replyId,
        params: {
          type: 'reply'
        }
      });

      if (error) throw new Error(error);

      // success
      const tempReplyData = replyData?.[commentId];
      const tempReplyList = tempReplyData?.replyList?.filter(
        (reply) => reply?._id !== replyId
      );
      tempReplyData.replyList = tempReplyList;

      setReplyData((prev) => {
        return { ...prev, [commentId]: tempReplyData };
      });

      //update reply count
      const tempCommentData = commentData?.[postId];
      const tempCommentList = tempCommentData?.commentList
        ? [...tempCommentData.commentList]
        : [];
      const findCommentIndex = tempCommentList?.findIndex(
        (comment) => comment?._id === commentId
      );
      const selectedComment = tempCommentList?.[findCommentIndex];
      selectedComment.replyCount = (selectedComment?.replyCount ?? 0) - 1;
      tempCommentList[findCommentIndex] = selectedComment;
      tempCommentData.commentList = tempCommentList;

      setCommentData((prev) => {
        return { ...prev, [postId]: tempCommentData };
      });

      onSuccess();
    } catch (error) {
      showToast({ text: error.message, type: 'error' });
    } finally {
      setDeleteLoadingMap((prev) => {
        return { ...prev, [replyId]: false };
      });
    }
  };

  const getCommentInfo = ({ postId }) => {
    const selectedCommentData = commentData?.[postId];
    const isSelectedCommentLoading = loadingMap?.[postId];

    return {
      commentInfo: selectedCommentData,
      commentList: selectedCommentData?.commentList,
      isCommentLoading: isSelectedCommentLoading
    };
  };

  const getReplyInfo = ({ commentId }) => {
    const selectedReplyData = replyData?.[commentId];
    const isSelectedReplyLoading = loadingMap?.[commentId];

    return {
      replyInfo: selectedReplyData,
      replyList: selectedReplyData?.replyList,
      isReplyLoading: isSelectedReplyLoading
    };
  };

  const onTextFieldChange = (value) => {
    setTextFieldValue(value);
  };

  const clearPostCommentsFromStore = ({ postId }) => {
    try {
      const selectedCommentData = commentData?.[postId];
      const selectedCommentList = selectedCommentData?.commentList;

      const tempReplyData = { ...replyData };
      const tempCommentData = { ...commentData };

      //delete all replies to all the post comment for the post
      selectedCommentList?.forEach((comment) => {
        const commentId = comment?._id;
        delete tempReplyData?.[commentId];
      });

      // delete all comments for the post
      delete tempCommentData?.[postId];

      setReplyData(tempReplyData);
      setCommentData(tempCommentData);
    } catch (e) {
      console.error(e);
      showToast({ text: e.mesage, type: 'error' });
    }
  };

  useEffect(() => {
    fetchComments({ postId });
  }, [postId]);

  return {
    // Replying comment
    setReplyingToCommentData,
    replyingToCommentData,

    submitLoadingMap,
    deleteLoadingMap,
    textFieldValue,
    onTextFieldChange,

    fetchComments,
    getCommentInfo,
    onCommentSubmit,
    onCommentDelete,
    fetchMoreComments,

    fetchReplies,
    getReplyInfo,
    onReplySubmit,
    onReplyDelete,
    fetchMoreReplies,

    clearPostCommentsFromStore,
    hasUnsavedInputField
  };
};

export default usePostComment;
