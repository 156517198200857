import { t } from '@/utility/localization';

export const PostCreatorTag = () => {
  return (
    <div className="px-4 py-2 rounded-4 bg-gradient-to-r from-[#FFEBA8] via-[#F9D7EB] to-[#D9D9FC]">
      <p className="text-label-sm text-[#3D3D3D95] font-medium">
        {t('creator')}
      </p>
    </div>
  );
};
